import classnames from "classnames";
import React, { Dispatch, useEffect, useRef, useState } from "react";

import useSubscription from "../Hooks/Subscription";

export interface AutocompleteSharedProps<T> {
  display: (option: T) => string;
  subDisplay?: (option: T) => string;
}

interface AutocompleteProps<T> extends AutocompleteSharedProps<T> {
  options: T[];
  index: number;
  setIndex: Dispatch<number>;
  selectAutocompleteOption: () => void;
}

function Autocomplete<T>({
  options,
  index: selectedIndex,
  setIndex,
  selectAutocompleteOption,
  display,
  subDisplay,
}: AutocompleteProps<T>) {
  const ref = useRef<HTMLUListElement>(null);

  const [shouldIgnoreScroll, setShouldIgnoreScroll] = useState(false);

  useSubscription(
    [selectedIndex] as const,
    [shouldIgnoreScroll] as const,
    (selectedIndex, ignoreScroll) => {
      if (ignoreScroll) return;
      const selectedElement = ref.current?.querySelector(`li:nth-of-type(${selectedIndex + 1})`);
      selectedElement?.scrollIntoView({
        block: "center",
      });
    }
  );

  useEffect(() => setShouldIgnoreScroll(false), [selectedIndex]);

  return (
    <ul
      ref={ref}
      className="absolute w-full max-h-40 overflow-y-auto bg-projectGray p-2 rounded z-50"
    >
      {options.map((option, index) => (
        <li
          key={index}
          className={classnames("rounded py-2 px-3 text-left mb-1 cursor-pointer", {
            "bg-projectLightGray": selectedIndex === index,
          })}
          onMouseEnter={() => {
            setIndex(index);
            // Don't scroll when mousing over elements
            setShouldIgnoreScroll(true);
          }}
          onMouseDown={() => selectAutocompleteOption()}
        >
          <div className="font-bold">{display(option)}</div>
          {subDisplay && <div className="text-sm">{subDisplay(option)}</div>}
        </li>
      ))}
    </ul>
  );
}

export default Autocomplete;
