import classnames from "classnames";
import React, { Dispatch } from "react";

import { SelectOption } from "../Core/Utilities";

interface ExpandedContentProps<T> {
  options: SelectOption<T>[];
  selected: number;
  setSelected: Dispatch<number>;
  optionsPerLine?: number;
  headers?: string[];
}

function ExpandedContent<T>({
  options,
  selected,
  setSelected,
  optionsPerLine,
  headers = [],
}: ExpandedContentProps<T>) {
  return (
    <div
      className="bg-black w-full h-full mt-3 justify-center border border-white b-rounded-md rounded-md"
      style={
        optionsPerLine === undefined
          ? { display: "flex" }
          : {
              display: "grid",
              gridTemplateColumns: `repeat(${optionsPerLine}, 1fr)`,
            }
      }
    >
      {headers.map((header, index) => (
        <p className="w-8 h-8 m-1 mb-2 py-2 text-center" key={index}>
          {header}
        </p>
      ))}
      {options.map((option, index) => (
        <button
          key={index}
          disabled={option.disabled}
          className={classnames("w-8 h-8 m-1 rounded", {
            "hover:bg-projectGray focus:bg-projectGray": !option.disabled && selected !== index,
            "bg-sand text-projectGray border-white border-1": selected === index,
            "text-white text-opacity-50": option.disabled,
            "custom-decorator relative": option.showDecorator && selected !== index,
          })}
          onClick={() => setSelected(index)}
        >
          {option.shortName}
        </button>
      ))}
    </div>
  );
}

export default ExpandedContent;
