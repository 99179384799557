import { useEffect, useRef } from "react";

/**
 * Subscribe to changes. Use when you would normally use a useEffect, but only want the effect to
 * trigger for a subset of the dependencies.
 * @param subscriptions when one or more of these values change, the effect is triggered
 * @param extraDeps extra dependencies needed by the effect, but that shouldn't trigger the effect
 * @param handler the effect handler, use the arguments passed to this function to ensure you have the correct state
 */
const useSubscription = <
  TSubscriptions extends readonly unknown[],
  TDependencies extends readonly unknown[]
>(
  subscriptions: TSubscriptions,
  extraDeps: TDependencies,
  handler: (...subs: [...TSubscriptions, ...TDependencies]) => void
) => {
  const extraDepsRef = useRef(extraDeps);

  useEffect(() => {
    extraDepsRef.current = extraDeps;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is already a dependency array
  }, extraDeps);

  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    handlerRef.current(...subscriptions, ...extraDepsRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This is already a dependency array
  }, subscriptions);
};

export default useSubscription;
