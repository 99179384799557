/* eslint-disable max-lines -- Special case file, don't care if it's long */
export interface Position {
  x: number;
  y: number;
  rotation?: 0 | 90 | 180 | 270;
  width?: number;
  height?: number;
}

export interface FloorElement extends Position {
  type:
    | "Meeting"
    | "Silent"
    | "Kitchen"
    | "Toilet"
    | "Lift"
    | "Gaming"
    | "Blank"
    | "Stairs"
    | "Accounting"
    | "Printer";
  title?: string;
}

interface FloorLayout {
  columns: number;
  rows: number;
  defaultDeskWidth: number;
  desks: Record<string, DeskPosition>;
  elements: FloorElement[];
}

export type DeskPosition = Position;

const floorDefaults = {
  columns: 85,
  rows: 25,
  defaultDeskWidth: 4,
};

const defaultElements: FloorElement[] = [];

const eleventhFloorElements: FloorElement[] = [
  {
    type: "Meeting",
    title: "Dansken",
    x: 2,
    y: 17,
    width: 11,
    height: 8,
  },
  {
    type: "Meeting",
    title: "POP IN 3",
    x: 13,
    y: 17,
    width: 6,
    height: 4,
  },
  {
    type: "Meeting",
    title: "POP IN 4",
    x: 13,
    y: 21,
    width: 6,
    height: 4,
  },
  {
    type: "Meeting",
    title: "Golden days",
    x: 19,
    y: 1,
    width: 6,
    height: 12,
  },
  {
    type: "Meeting",
    title: "Gretas",
    x: 25,
    y: 17,
    width: 6,
    height: 8,
  },
  {
    type: "Meeting",
    title: "Kometen",
    x: 31,
    y: 17,
    width: 10,
    height: 8,
  },
  {
    type: "Meeting",
    title: "Locatelli",
    x: 41,
    y: 17,
    width: 6,
    height: 8,
  },
  {
    type: "Kitchen",
    title: "",
    x: 73,
    y: 1,
    width: 12,
    height: 12,
  },
  {
    type: "Meeting",
    title: "POP IN 5",
    x: 47,
    y: 17,
    width: 6,
    height: 4,
  },
  {
    type: "Meeting",
    title: "POP IN 6",
    x: 47,
    y: 21,
    width: 6,
    height: 4,
  },
  {
    type: "Meeting",
    title: "POP IN 7",
    x: 59,
    y: 17,
    width: 6,
    height: 4,
  },
  {
    type: "Meeting",
    title: "POP IN 8",
    x: 59,
    y: 21,
    width: 6,
    height: 4,
  },
  {
    type: "Blank",
    x: 65,
    y: 24,
    width: 20,
    height: 1,
  },
];

export const floorLayout: Record<string, FloorLayout> = {
  "5ac27249-cfa7-42aa-9764-e0a3f0408905": {
    // Floor 11
    ...floorDefaults,
    desks: {
      "1": { x: 2, y: 1, rotation: 90 },
      "2": { x: 2, y: 5, rotation: 90 },
      "3": { x: 2, y: 9, rotation: 90 },

      "4": { x: 6, y: 1, rotation: 270 },
      "5": { x: 6, y: 5, rotation: 270 },
      "6": { x: 6, y: 9, rotation: 270 },

      "7": { x: 10, y: 1, rotation: 90 },
      "8": { x: 10, y: 5, rotation: 90 },
      "9": { x: 10, y: 9, rotation: 90 },

      "10": { x: 14, y: 1, rotation: 270 },
      "11": { x: 14, y: 5, rotation: 270 },
      "12": { x: 14, y: 9, rotation: 270 },

      "13": { x: 29, y: 1, rotation: 90 },
      "14": { x: 29, y: 5, rotation: 90 },
      "15": { x: 29, y: 9, rotation: 90 },

      "16": { x: 33, y: 1, rotation: 270 },
      "17": { x: 33, y: 5, rotation: 270 },
      "18": { x: 33, y: 9, rotation: 270 },

      "19": { x: 37, y: 1, rotation: 90 },
      "20": { x: 37, y: 5, rotation: 90 },
      "21": { x: 37, y: 9, rotation: 90 },

      "22": { x: 41, y: 1, rotation: 270 },
      "23": { x: 41, y: 5, rotation: 270 },
      "24": { x: 41, y: 9, rotation: 270 },

      "25": { x: 45, y: 1, rotation: 90 },
      "26": { x: 45, y: 5, rotation: 90 },
      "27": { x: 45, y: 9, rotation: 90 },

      "28": { x: 49, y: 1, rotation: 270 },
      "29": { x: 49, y: 5, rotation: 270 },
      "30": { x: 49, y: 9, rotation: 270 },

      "31": { x: 53, y: 1, rotation: 90 },
      "32": { x: 53, y: 5, rotation: 90 },
      "33": { x: 53, y: 9, rotation: 90 },

      "34": { x: 57, y: 1, rotation: 270 },
      "35": { x: 57, y: 5, rotation: 270 },
      "36": { x: 57, y: 9, rotation: 270 },

      "37": { x: 61, y: 1, rotation: 90 },
      "38": { x: 61, y: 5, rotation: 90 },
      "39": { x: 61, y: 9, rotation: 90 },

      "40": { x: 65, y: 1, rotation: 270 },
      "41": { x: 65, y: 5, rotation: 270 },
      "42": { x: 65, y: 9, rotation: 270 },

      "43": { x: 69, y: 18 },
      "44": { x: 73, y: 18 },
      "45": { x: 77, y: 18 },
      "46": { x: 81, y: 18 },

      "47": { x: 69, y: 20 },
      "48": { x: 73, y: 20 },
      "49": { x: 77, y: 20 },
      "50": { x: 81, y: 20 },
    },
    elements: [...defaultElements, ...eleventhFloorElements],
  },
};

export const calculatePositionStyles = (
  { x, y, width, height, rotation }: Position,
  defaultWidth = 5,
  defaultHeight = 5
) => {
  return {
    gridRow: `${y}/${y + (height ?? defaultHeight)}`,
    gridColumn: `${x}/${x + (width ?? defaultWidth)}`,
    transform: `rotate(${rotation}deg) translateX(-50%)`,
    transformOrigin: "bottom left",
  };
};
