import { Popover, Transition } from "@headlessui/react";
import classnames from "classnames";
import findIndex from "lodash/findIndex";
import React, { HTMLAttributes, useCallback, useState } from "react";

import { Svg } from "../Core/Components";
import useSubscription from "../Core/Hooks/Subscription";
import { SelectOption } from "../Core/Utilities";
import ExpandedContent from "./ExpandedContent";
import ToolbarButton from "./ToolbarButton";

type NativeSelectProps = HTMLAttributes<HTMLSelectElement>;

interface SelectProps<T> {
  options: SelectOption<T>[];
  selected: T;
  setSelected: (selected: T) => void;
  compareBy?: keyof T;
  optionsPerLine?: number;
  headers?: string[];
}

function findSelectedIndex<T>(
  options: SelectOption<T>[],
  compareBy: keyof T | undefined,
  selected: T
) {
  return findIndex(options, (option) =>
    compareBy === undefined
      ? option.value === selected
      : option.value[compareBy] === selected[compareBy]
  );
}

function Select<T>({
  options,
  selected,
  setSelected,
  compareBy,
  optionsPerLine,
  className,
  headers,
  ...rest
}: SelectProps<T> & NativeSelectProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const [index, setIndex] = useState(findSelectedIndex(options, compareBy, selected));

  useSubscription(
    [selected] as const,
    [options, compareBy] as const,
    (selected, options, compareBy) => {
      setIndex(findSelectedIndex(options, compareBy as keyof T | undefined, selected));
    }
  );

  useSubscription([index] as const, [options] as const, (index, options) => {
    if (index !== -1) setSelected(options[index].value);
  });

  const calculateNewIndex = useCallback(
    (index: number, difference: number) => (index + options.length + difference) % options.length,
    [options]
  );

  const moveIndex = (difference: number) => {
    setIndex((index) => {
      let newIndex = calculateNewIndex(index, difference);
      while (options[newIndex]?.disabled) {
        newIndex = calculateNewIndex(newIndex, difference < 0 ? -1 : 1);
        if (newIndex === index) break; // Prevent infinite loop if all options are disabled
      }
      return newIndex;
    });
  };

  return (
    <div className={classnames("flex transition-all ease-in-out duration-200 gap-1", className)}>
      <ToolbarButton className="w-10" onClick={() => moveIndex(-1)} svgRecolorMode="fill">
        <Svg name="ChevronLeft" />
      </ToolbarButton>
      <Popover className="relative">
        <Popover.Button
          aria-expanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          className="px-4 h-10 rounded-md bg-projectLightGray hover:bg-sand hover:text-projectGray min-w-20 select-none"
        >
          {index === -1 ? "" : options[index]?.longName}
        </Popover.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel className="absolute z-20 left-1/2 transform -translate-x-1/2">
            <ExpandedContent<T>
              options={options}
              selected={index}
              setSelected={setIndex}
              optionsPerLine={optionsPerLine}
              headers={headers}
              {...rest}
            />
          </Popover.Panel>
        </Transition>
      </Popover>
      <ToolbarButton className="w-10" onClick={() => moveIndex(1)} svgRecolorMode="fill">
        <Svg name="ChevronRight" />
      </ToolbarButton>
    </div>
  );
}

export default Select;
