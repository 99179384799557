import { useMsal } from "@azure/msal-react";
import { Transition } from "@headlessui/react";
import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import Backdrop from "../Core/Backdrop/Backdrop";
import { IconButton, Svg } from "../Core/Components";

const listItem =
  "flex justify-between items-center border-white border-t font-bold cursor-pointer hover:underline";

const link = "py-5 w-full ";

interface MenuProps {
  toggleMenu: () => void;
  active: boolean;
}

const menuOptions = [
  { title: "Mina bokningar", link: "/bookings" },
  { title: "Kollegor på kontoret", link: "/overview" },
  {
    title: "Feedback",
    link: "https://knowitexperience.slack.com/archives/C01LB91L52S",
    external: true,
  },
];

const Menu = ({ toggleMenu, active }: MenuProps) => {
  const { instance } = useMsal();
  return (
    <>
      <Backdrop active={active} onClick={toggleMenu} />
      <div className="fixed top-0 bottom-0 right-0 z-30">
        <Transition
          className="bg-black py-8 px-8 fixed shadow-2xl h-full z-10 relative w-96 max-w-screen shadow-3xl"
          enter="transition-all duration-150"
          enterFrom="transform translate-x-96 opacity-0"
          enterTo="transform translate-x-0 opacity-100"
          leave="transition-all duration-150"
          leaveFrom="transform translate-x-0 opacity-100"
          leaveTo="transform translate-x-96 opacity-0"
          show={active}
        >
          <IconButton
            className="ml-auto mb-12 h-8"
            onClick={toggleMenu}
            icon={<Svg name="Close" className="h-6" />}
          >
            <span className="text-white font-bold">Meny</span>
          </IconButton>
          <ul className="text-white" id="menu">
            {menuOptions.map((option, index) => (
              <li
                key={index}
                className={classnames(
                  listItem,
                  "transform transition-transform duration-100 hover:scale-105 hover:underline",
                  {
                    "border-b": index === menuOptions.length - 1, // Border-bottom for last element
                  }
                )}
                onClick={toggleMenu}
              >
                {option.external ? (
                  <a href={option.link} className={link} target="_blank" rel="noopener noreferrer">
                    {option.title}
                  </a>
                ) : (
                  <Link to={option.link} className={link}>
                    {option.title}
                  </Link>
                )}
                <Svg name="ArrowRight" className="w-8 ml-6" />
              </li>
            ))}
          </ul>
          <div
            className="Menu__sign-out flex justify-end mt-16 cursor-pointer transform transition-transform duration-100 hover:scale-105"
            onClick={async () => {
              await instance.logout();
              toggleMenu();
            }}
          >
            <span className="text-white font-bold self-center">Logga ut</span>
            <Svg name="WavingHand" className="ml-4" />
          </div>
        </Transition>
      </div>
    </>
  );
};

export default Menu;
