import { useCallback, useState } from "react";

export const useSelectedIndex = (options: number, initialIndex = 0, wrapAround = false) => {
  const [index, setIndex] = useState(initialIndex);

  const moveIndex = useCallback(
    (difference: number) =>
      setIndex((index) => {
        const newIndex = index + difference;
        if (wrapAround) return (newIndex + options) % options;

        return newIndex >= options || newIndex < 0 ? index : newIndex;
      }),
    [wrapAround, setIndex, options]
  );

  return {
    index,
    setIndex,
    moveIndex,
  };
};
