import React, { useMemo } from "react";

import { dayNames, dayNamesShort } from "../Core/Constants";
import { Role, useDates, useDesks, useFloors, useRoles } from "../Core/Contexts";
import { Day, SelectOption } from "../Core/Utilities";
import { Floor } from "../Generated/floors_pb";
import BookingCount from "./BookingCount";
import MemberSelect from "./MemberSelect";
import Select from "./Select";

const getDayString = (day: Day, isToday: boolean) => {
  if (isToday) return "Idag";

  return `${day.name} ${day.date.toLocaleDateString("sv-SE", { month: "short", day: "numeric" })}`;
};

const Toolbar = () => {
  const { selectedFloor, setSelectedFloor, floors } = useFloors();
  const { days, selectedDay, setSelectedDay } = useDates();
  const { selectedDesk, setSelectedDesk } = useDesks();

  const { hasRole } = useRoles();

  function setSelectedFloorLocal(floor: Floor.AsObject): void {
    if (selectedDesk) setSelectedDesk(undefined);
    setSelectedFloor(floor);
  }

  const floorOptions: SelectOption<Floor.AsObject>[] = useMemo(
    () =>
      floors.map((floor) => ({
        value: floor,
        shortName: floor.description.charAt(0),
        longName: floor.description,
      })),
    [floors]
  );

  const dayOptions: SelectOption<Day | undefined>[] = useMemo(() => {
    const today = days[0];

    const previousDays: SelectOption<Day | undefined>[] = [];

    // Start at current day
    const previousDay = new Date(today.date);
    for (let i = 0; i < today.dayNumber; i++) {
      const dayName = dayNames[(i + 1) % 7];
      // Decrement date by one for every iteration
      previousDay.setDate(previousDay.getDate() - 1);
      // Unshift previous day to front of array
      previousDays.unshift({
        value: undefined,
        disabled: true,
        longName: dayName,
        shortName: previousDay.getDate().toString(),
      });
    }
    return [
      ...previousDays,
      ...days.map((day) => ({
        value: day,
        longName: getDayString(day, days[0] === day),
        shortName: day.date.getDate().toString(),
        showDecorator: days[0] === day,
      })),
    ];
  }, [days]);

  return (
    <div className="flex flex-col items-center gap-4 lg:flex-row bg-black w-full py-1 md:py-5 md:px-8">
      <Select
        className="order-3 lg:order-1 lg:mx-0 z-20 flex-1 max-w-sm"
        options={dayOptions}
        selected={selectedDay}
        setSelected={(day) => {
          if (day) setSelectedDay(day);
        }}
        optionsPerLine={7}
        headers={dayNamesShort}
      />
      {selectedFloor && floorOptions.length > 0 && (
        <div className="flex items-center gap-4 flex-col lg:flex-row order-5 flex-1">
          <Select<Floor.AsObject>
            className="mx-0 z-10"
            options={floorOptions}
            selected={selectedFloor}
            setSelected={setSelectedFloorLocal}
            compareBy={"id"}
          />
          <BookingCount />
        </div>
      )}
      {hasRole(Role.seating) && (
        <div className="order-6 flex-1 justify-end hidden lg:flex">
          <MemberSelect />
        </div>
      )}
    </div>
  );
};

export default Toolbar;
