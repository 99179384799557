import * as jspb from "google-protobuf";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

import { Booking, GetBookingCountRequest, ListBookingsRequest } from "../../Generated/bookings_pb";
import { Member } from "../../Generated/members_pb";
import { AnyObject } from "./type.utilities";

type Constructor<T> = new (...args: any[]) => T;

// Define mappings from objects to gRPC Messages
type MessageMapping<TMessage extends AnyObject = AnyObject> = {
  [prop in keyof TMessage]?: Constructor<jspb.Message>;
};

interface Mapping<TMessage extends AnyObject = AnyObject> {
  mapping?: MessageMapping<TMessage>;
  ignore?: string[];
}

const map = new Map<Constructor<jspb.Message>, Mapping>([
  [
    Booking,
    <Mapping<Booking.AsObject>>{
      mapping: {
        date: Timestamp,
        member: Member,
      },
    },
  ],
  [
    Member,
    <Mapping<Member.AsObject>>{
      ignore: ["roles"],
    },
  ],
  [
    ListBookingsRequest,
    <Mapping<ListBookingsRequest.AsObject>>{
      mapping: {
        from: Timestamp,
        to: Timestamp,
      },
    },
  ],
  [
    GetBookingCountRequest,
    <Mapping<GetBookingCountRequest.AsObject>>{
      mapping: {
        from: Timestamp,
        to: Timestamp,
      },
    },
  ],
]);

export function assignMessageProperties<TSource extends AnyObject, TMessage extends jspb.Message>(
  message: TMessage,
  obj: TSource,
  mapping?: Mapping<TSource>
): TMessage {
  for (const key in obj) {
    if (mapping?.ignore?.includes(key)) continue;
    const setterName = `set${key.charAt(0).toUpperCase()}${key.substring(
      1,
      key.length
    )}` as keyof TMessage;

    if (message[setterName] === undefined) throw new Error(`Could not find setter '${setterName}'`);

    const propertyMessage = mapping?.mapping?.[key];

    // @ts-expect-error Type of obj[key] cannot be checked properly here
    const value = propertyMessage ? messageFromObject(propertyMessage, obj[key]) : obj[key];
    // @ts-expect-error TypeScript is unable to infer that this is is function
    message[setterName](value);
  }
  return message;
}

export function messageFromObject<TSource extends AnyObject, TMessage extends jspb.Message>(
  Constr: Constructor<TMessage>,
  obj: TSource
): TMessage {
  const message = new Constr();
  const mapping = map.get(Constr);
  return assignMessageProperties(message, obj, mapping);
}

export const timestampToDate = (timestamp: Timestamp | Timestamp.AsObject): Date => {
  const { seconds, nanos } = timestamp instanceof Timestamp ? timestamp.toObject() : timestamp;
  return new Date(seconds * 1000 + nanos / 1000000);
};

export const dateToTimestamp = (date: Date): Timestamp => {
  const timestamp = new Timestamp();
  timestamp.setSeconds(Math.floor(date.getTime() / 1000));
  timestamp.setNanos(date.getMilliseconds() * 1000000);
  return timestamp;
};
