import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import { useMemo } from "react";
import { createSuperContext } from "react-super-context";

import { useBookings, useDates, useMemberList } from ".";
import { Day } from "../Utilities";

/**
 * This context contains bookings for the current selected member and is used
 * to check which days can be booked and if a booking can be moved to another desk
 */
const [memberBookingContext, useMemberBookings] = createSuperContext(() => {
  const { selectedMember } = useMemberList();
  const { bookings } = useBookings();

  const memberBookings = useMemo(() => {
    const bookingsForMember = bookings.filter(
      (booking) => booking.member?.id === selectedMember.id
    );
    return orderBy(bookingsForMember, (booking) => booking.date?.seconds);
  }, [bookings, selectedMember]);

  const { days } = useDates();

  const getMemberBooking = useMemo(() => {
    const bookingsBySeconds = keyBy(memberBookings, (booking) => booking.date?.seconds ?? 0);
    const memberBookingsByDay = days.map((day) => bookingsBySeconds[day.dateAsTimestamp.seconds]);
    return (day: Day | undefined) => (day ? memberBookingsByDay[day.index] : undefined);
  }, [memberBookings, days]);

  return {
    /**
     * List of bookings in the current timespan for the selected member
     */
    memberBookings,
    /**
     * Get booking for the current selected member, if it exists, on the given day.
     */
    getMemberBooking,
  };
});

export { memberBookingContext, useMemberBookings };
