import React, {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Snackbar } from "../Components";

interface SnackbarMessage {
  message: string;
  prefix?: ReactNode;
}

export type SnackbarError = string | { message?: string } | Error;

interface SnackbarContextModel {
  setSnackbarMessage: Dispatch<SnackbarMessage | undefined>;
  displayError: (error?: SnackbarError, prefix?: ReactNode) => void;
}

const Context = createContext({} as SnackbarContextModel);

export const SnackbarContext = ({ children }: { children?: ReactNode }) => {
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage | undefined>(undefined);
  const [showMessage, setShowMessage] = useState(false);

  const displayError = useCallback((error?: SnackbarError, prefix?: ReactNode) => {
    if (error === undefined) return;
    const message = typeof error === "string" ? error : error.message ?? "Unknown error.";
    setSnackbarMessage({ message, prefix });
  }, []);

  useEffect(() => setShowMessage(!!snackbarMessage), [snackbarMessage]);

  const onClose = () => {
    setShowMessage(false);
  };

  return (
    <Context.Provider value={{ setSnackbarMessage, displayError }}>
      <Snackbar
        message={snackbarMessage?.message}
        onClose={onClose}
        open={showMessage}
        prefix={snackbarMessage?.prefix}
      />
      {children}
    </Context.Provider>
  );
};

export const useSnackbar = () => useContext(Context);

export const useSnackbarError = (error: SnackbarError | undefined, prefix?: string) => {
  const { displayError } = useSnackbar();
  useEffect(() => displayError(error, prefix), [displayError, error, prefix]);
};
