import { validate as validateEmail } from "email-validator";
import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import usePromise from "react-use-promise";

import { AutocompleteInput, Button, Checkbox, Input } from "../Core/Components";
import { useApiClient, useMember, useMemberList } from "../Core/Contexts";
import useSubscription from "../Core/Hooks/Subscription";
import { ListMembersRequest, Member } from "../Generated/members_pb";

interface MemberSelectFormProps {
  setDialogOpen: React.Dispatch<boolean>;
}

const maxAutocompleteSuggestions = 10;

const makeCaseInsensitiveComparer = (string: string) => {
  const regExp = new RegExp(string, "i");
  return (member: Member.AsObject) => regExp.test(member.name) || regExp.test(member.email);
};

const MemberSelectForm = ({ setDialogOpen }: MemberSelectFormProps) => {
  const client = useApiClient();
  const { selectedMember, setSelectedMember } = useMemberList();
  const loggedInMember = useMember();

  const [members, membersError] = usePromise(async () => {
    const request = new ListMembersRequest();
    request.setIncludeExternal(true);

    const response = await client.listMembers(request);
    return response.getMembersList().map((member) => member.toObject());
  }, []);

  // This form's current selected member (not yet submitted)
  const [selected, setSelected] = useState<Member.AsObject | undefined>(() =>
    selectedMember.id === loggedInMember.id ? undefined : selectedMember
  );

  // Value of the name input field
  const [nameInput, setNameInput] = useState(selected?.name ?? "");
  // Value of the email input field
  const [emailInput, setEmailInput] = useState(selected?.email ?? "");
  // True indicates that the selected member is an external member (not a member of Knowit)
  const [isExternal, setIsExternal] = useState(selected?.external ?? false);

  // Autocomplete options based on nameInput
  const options = useMemo(() => {
    if (!members || nameInput.length < 1 || isExternal) return [];
    const filtered = members
      .filter(makeCaseInsensitiveComparer(nameInput))
      .slice(0, maxAutocompleteSuggestions);
    return selected ? filtered.filter((member) => member.id !== selected.id) : filtered;
  }, [selected, nameInput, members, isExternal]);

  // Form is valid if an internal member is selected or the name and email input fields are valid
  const isValid =
    (!isExternal && selected !== undefined) || (nameInput.length > 0 && validateEmail(emailInput));

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    // Empty id indicates external member to the API
    const memberToSelect: Member.AsObject | undefined = isExternal
      ? selected ?? {
          id: "",
          email: emailInput,
          name: nameInput,
          external: true,
          active: true,
          givenName: "",
          surname: "",
          mobilePhone: "",
        }
      : selected;
    if (!memberToSelect) return;
    setSelectedMember(memberToSelect);
    setDialogOpen(false);
  };

  // When an internal member is selected, set the email
  useSubscription([selected] as const, [isExternal] as const, (selected, external) => {
    if (selected) {
      setEmailInput(selected.email);
      if (selected.external) {
        setIsExternal(true);
      }
    } else if (!external) {
      setEmailInput("");
    }
  });

  // If the input field is edited after a member is selected, clear the selection
  useEffect(() => {
    if (selected?.name !== nameInput) setSelected(undefined);
  }, [selected, nameInput]);

  const ref = useRef<HTMLFormElement>(null);
  const focusElement = useCallback(
    (querySelector: string) => {
      const input = ref.current?.querySelector<HTMLInputElement>(querySelector);
      input?.focus();
      input?.select();
    },
    [ref]
  );

  // When component is loaded, focus the name input
  useEffect(() => focusElement("input#member-name-input"), [focusElement]);
  return (
    <>
      {membersError && <p className="text-statusRed">{membersError}</p>}
      <form onSubmit={submit} className="py-4 pb-20" ref={ref}>
        <AutocompleteInput<Member.AsObject>
          id="member-name-input"
          label="Namn"
          name="name"
          type="name"
          value={nameInput}
          setValue={setNameInput}
          autocompleteOptions={options}
          setSelectedOption={setSelected}
          display={(option) => option.name}
          subDisplay={(option) => option.email}
        />
        <Checkbox
          className="mt-8"
          name="external"
          label="Extern"
          checked={isExternal}
          setChecked={(value) => {
            setIsExternal(value);
            setEmailInput(value || selected?.id === "" ? "" : selected?.email ?? "");
            const focus =
              nameInput.length === 0 || selected?.id === ""
                ? "input#member-name-input"
                : "input#member-email-input";
            requestAnimationFrame(() => focusElement(focus));
          }}
        />
        <Input
          id="member-email-input"
          className="mt-8"
          label="E-post"
          name="email"
          type="email"
          value={emailInput}
          onChange={(event) => setEmailInput(event.target.value)}
          disabled={!isExternal}
        />
        <Button className="mx-auto mt-8" color="statusGreen" disabled={!isValid} animate={true}>
          Bekräfta
        </Button>

        {selectedMember.id !== loggedInMember.id && (
          <button
            type="button"
            className="mx-auto mt-8 underline"
            onClick={() => {
              setSelectedMember(loggedInMember);
              setDialogOpen(false);
            }}
          >
            Byt tillbaka till {loggedInMember.name}
          </button>
        )}
      </form>
    </>
  );
};

export default MemberSelectForm;
