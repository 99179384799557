import React from "react";

import { Svg } from "../Core/Components";

interface FurtherDetailsModalProps {
  details: string;
  header: string;
  onClick: (event: React.MouseEvent) => void;
}

const DeskDetailsModal = ({ details, header, onClick }: FurtherDetailsModalProps) => {
  return (
    <div className="custom-modal absolute shadow-md rounded bg-projectGray text-projectVeryLightGray w-64 left-0">
      <div className="p-4">
        <div className="flex justify-between items-center pb-2">
          <span className=" text-sm leading-4 font-bold">{header}</span>
          <div className="w-5 p-1 cursor-pointer" onClick={onClick}>
            <Svg name="Close" />
          </div>
        </div>
        <p className="text-sm leading-4">{details}</p>
      </div>
    </div>
  );
};

export default DeskDetailsModal;
