import { Transition } from "@headlessui/react";
import React from "react";

interface BackdropProps {
  active: boolean;
  onClick: () => void;
}

const Backdrop = ({ active, onClick }: BackdropProps) => (
  <Transition
    className="absolute top-0 w-screen h-screen bg-transparent z-30"
    style={{ backdropFilter: "blur(3px)", background: "rgba(51, 51, 51, 0.8)" }}
    enter="transition-opacity duration-150"
    enterFrom="transform opacity-0"
    enterTo="transform opacity-100"
    leave="transition-opacity duration-150"
    leaveFrom="transform opacity-100"
    leaveTo="transform opacity-0"
    show={active}
    onClick={onClick}
  />
);

export default Backdrop;
