import { useMsal } from "@azure/msal-react";
import { useMemo } from "react";
import { createSuperContext } from "react-super-context";

import { BookItPromiseClient } from "../../Generated/services_grpc_web_pb";
import { MsalScopes } from "../Configuration";

interface GrpcRequest {
  getCallOptions: () => unknown;
  getMetadata: () => Record<string, string>;
  getMethodDescriptor: () => unknown;
  getRequestMessage: () => unknown;
}

type InvokeNext = (request: GrpcRequest) => Promise<unknown>;

const hostname = process.env.REACT_APP_KONTORBOOKING_API_PATH ?? "";

const [apiClientContext, useApiClient] = createSuperContext(() => {
  const { instance } = useMsal();

  return useMemo(() => {
    const account = instance.getActiveAccount() ?? instance.getAllAccounts()[0];
    const authInterceptor = {
      async intercept(request: GrpcRequest, invokeNext: InvokeNext) {
        const metadata = request.getMetadata();

        const token = await instance.acquireTokenSilent({
          scopes: MsalScopes,
          account,
        });

        metadata.Authorization = `Bearer ${token.accessToken}`;

        return invokeNext(request);
      },
    };

    return new BookItPromiseClient(hostname, null, {
      unaryInterceptors: [authInterceptor],
    });
  }, [instance]);
});

export { apiClientContext, useApiClient };
