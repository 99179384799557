import classnames from "classnames";
import React from "react";

import DashedLines from "../../assets/svgs/traffic-stripes.svg";
import waves from "../../assets/svgs/waves.svg";

interface MapBoundaryTypes {
  type: "Waves" | "DashedLines";
  position: "Top" | "Bottom";
  description: string;
}

const MapBoundaries = ({ type, position, description }: MapBoundaryTypes) => {
  const separatorStyling = "h-8 w-full border-projectGray";

  return (
    <div
      className={classnames(
        "px-8 h-8 bg-projectLightGray align-middle flex w-full justify-center border-projectGray",
        {
          "border-t-1": position === "Bottom",
          "border-b-1": position === "Top",
        }
      )}
    >
      <div
        className={separatorStyling}
        style={{
          background: `url(${type === "Waves" ? waves : DashedLines}) repeat-x center center`,
        }}
      />
      <p className="text-white px-4 4/12 text-center whitespace-nowrap leading-8">{description}</p>
      <div
        className={separatorStyling}
        style={{
          background: `url(${type === "Waves" ? waves : DashedLines}) repeat-x center center`,
        }}
      />
    </div>
  );
};

export default MapBoundaries;
