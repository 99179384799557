import React from "react";

import { Button, Svg } from "../Core/Components";
import { useDates } from "../Core/Contexts";
import { formatDate } from "../Core/Utilities";
import { Booking } from "../Generated/bookings_pb";

interface BookingItemProps {
  bookings: Booking.AsObject[];
  deskless: boolean;
  title: string;
  onClick: (booking: Booking.AsObject) => void;
  showName: boolean;
}

const BookingItem = ({ bookings, deskless, title, onClick, showName }: BookingItemProps) => {
  const { days } = useDates();
  return (
    <div className="bg-black w-10/12 md:w-8/12 lg:w-6/12 xl:w-4/12 my-5 p-5 mx-auto rounded">
      <p className="text-2xl text-white">{deskless ? "Plats utan skrivbord" : `Plats ${title}`}</p>
      {bookings.map((booking) => {
        const day = days.find((day) => day.dateAsTimestamp.seconds === booking.date?.seconds);
        return (
          <Button
            key={booking.id}
            onClick={() => onClick(booking)}
            className="w-full my-2 text-left flex justify-between"
          >
            <span>{formatDate(day?.date, { month: undefined })}</span>
            {showName && <span>{booking.member?.name}</span>}
            <Svg name="RemoveBooking" className="w-4 h-4" />
          </Button>
        );
      })}
    </div>
  );
};

export default BookingItem;
