import React from "react";

import { LottieComponent } from "../Core/Components";

const BookingStepSuccess = () => {
  return (
    <div className="my-12">
      <h2 className="text-2xl leading-7 font-bold text-center text-projectVeryLightGray">Bokad!</h2>
      <div className="w-1/2 max-w-spark mx-auto my-8">
        <LottieComponent variant="vSign" />
      </div>
    </div>
  );
};

export default BookingStepSuccess;
