import sumBy from "lodash/sumBy";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { useBookings, useDates, useDesks } from "../Core/Contexts";
import { DeskType } from "../Generated/desks_pb";

const BookingCount = () => {
  const { desks } = useDesks();
  const { getBooking, getBookingCount, maxBookingsPerDay } = useBookings();
  const { selectedDay } = useDates();

  const [totalAvailable, desksAvailable] = useMemo(() => {
    const desksAvailable = sumBy(desks, (desk) =>
      desk.type === DeskType.DESK_TYPE_PERSONAL || desk.disabled || getBooking(desk, selectedDay)
        ? 0
        : 1
    );
    const totalAvailable = Math.max(0, maxBookingsPerDay - getBookingCount(selectedDay));

    return [totalAvailable, desksAvailable];
  }, [getBookingCount, desks, getBooking, maxBookingsPerDay, selectedDay]);

  const isOnlyDesklessAvailable = desksAvailable === 0 && totalAvailable > 0;

  return (
    <div className="flex flex-col text-projectVeryLightGray font-bold">
      <span className="whitespace-nowrap">{desksAvailable} lediga platser</span>
      {isOnlyDesklessAvailable && (
        <span className="underline font-normal whitespace-nowrap">
          <Link to="/free-booking">Reservera utan skrivbord</Link>
        </span>
      )}
    </div>
  );
};

export default BookingCount;
