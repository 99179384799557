import React from "react";

import { Svg } from "../Core/Components";
import { useBookings } from "../Core/Contexts";
import bgImage from "../assets/images/desk-bg-image.jpg";

const CleanDesk = () => {
  const { maxBookingsPerDay } = useBookings();

  const textList = [
    `Du booker en spesifikk arbeidsstasjon i kartet som sendes ut ukentlig. Det finnes ${maxBookingsPerDay} av disse pultene, og alle er merket med nummer.`,
    "Arbeidsstasjonen er utstyrt med skjerm og ledninger som gjør at de aller, aller fleste vil kunne koble seg opp med sin egen laptop.",
    "Tastatur, mus, lader og eventuelt annet ekstrautstyr henter du fra skapet ditt (eller fra medbragt sekk/veske dersom du foretrekker det). Dersom du ikke har skap, lar det seg ordne lett som bare det. Kontakt Birgitte.",
    "Pulten er rengjort, ren og pen når du kommer på jobb. Ta likevel gjerne en runde med antibac på berøringsflater før du starter dagen.",
    "Når dagens arbeidsdag er over, pakker du bort tastatur, mus, lader og oppbevarer det i skapet ditt (eller, som sagt, din egen veske eller sekk).",
    "Pultene blir rengjort daglig. Din oppgave blir å forlate arbeidsstasjonen i nøyaktig den stand den var i, da du forlot den.",
  ];

  return (
    <div
      className="absolute h-full w-full bg-right sm:bg-center bg-no-repeat bg-cover overflow-auto"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className="navbar-offset" />
      <div className="w-10/12 sm:w-6/12 xl:w-4/12 flex-col justify-center py-4 sm:pt-20 mx-auto">
        <div className="m-auto mb-5 sm:mb-8 w-16 sm:w-auto max-w-spark">
          <Svg name="Spark" />
        </div>
        <h1 className="text-center text-white text-2xl md:text-4xl font-bold ">Clean desk</h1>
        <p className="text-melon text-center md:text-xl pt-6 mb-4 sm:mb-6">
          Nye tider, nye systemer. Hvordan funker egentlig dette Clean Desk-opplegget?
        </p>
        <ol style={{ listStyle: "decimal" }}>
          {textList.map((text, index) => (
            <li key={index} className={"pt-4 text-white leading:5"}>
              {text}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default CleanDesk;
