import { Configuration } from "@azure/msal-browser";

const clientId = "d6c46f86-4273-411a-9733-f837d7d6dbdf";
const tenantId = "6735929c-9dbf-473b-9fc6-5fbdcd2c9fc4";

const MsalConfiguration: Configuration = Object.freeze({
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: window.origin + (process.env.PUBLIC_URL ?? ""),
  },
});

const MsalScopes = Object.freeze([
  "api://d6c46f86-4273-411a-9733-f837d7d6dbdf/access_as_user",
  "User.Read",
]) as string[];

export { MsalConfiguration, MsalScopes };
