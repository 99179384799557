import React, { useCallback, useState } from "react";

import { IconButton, Svg } from "../Core/Components";
import {
  Role,
  useBookings,
  useDates,
  useDesks,
  useMember,
  useMemberBookings,
  useMemberList,
  useRoles,
} from "../Core/Contexts";
import useDeskState from "../Core/Hooks/DeskState";
import useSubscription from "../Core/Hooks/Subscription";
import { Day, GrpcError } from "../Core/Utilities";
import { Booking } from "../Generated/bookings_pb";
import { MemberSelectModal } from "../MemberSelect";
import BookingFormStepButtons from "./BookingFormStepButtons";
import BookingStepConfirmation from "./BookingStepConfirmation";
import BookingStepDaysSelect from "./BookingStepDaysSelect";
import BookingStepSuccess from "./BookingStepSuccess";
import DeskDetails from "./DeskDetails";

interface ReservationContainerProps {
  freeBooking?: boolean;
}

const BookingForm = ({ freeBooking = false }: ReservationContainerProps) => {
  const { selectedDesk, setSelectedDesk } = useDesks();
  const { saveBookings } = useBookings();
  const { getMemberBooking } = useMemberBookings();
  const { selectedMember, setSelectedMember } = useMemberList();
  const { selectedDay } = useDates();
  const loggedInMember = useMember();
  const { hasRole } = useRoles();
  const getDeskState = useDeskState(selectedDesk, freeBooking);

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedDays, setSelectedDays] = useState<Set<Day>>(() => {
    if (freeBooking) return new Set();
    const deskState = getDeskState(selectedDay);
    return deskState === "disabled" || deskState === "taken" || deskState === "full"
      ? new Set()
      : new Set([selectedDay]);
  });

  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);
  const [submitError, setSubmitError] = useState<GrpcError>();

  const toggleDay = useCallback(
    (day: Day) => {
      const newSelectedDays = new Set(selectedDays);
      if (newSelectedDays.has(day)) {
        newSelectedDays.delete(day);
      } else {
        newSelectedDays.add(day);
      }
      setSelectedDays(newSelectedDays);
    },
    [selectedDays, setSelectedDays]
  );

  // If selectedDesk changes to a desk that has a booking on the selected day, then deselect the day
  useSubscription(
    [selectedDesk, selectedDay, getDeskState] as const,
    [selectedDays, toggleDay] as const,
    // eslint-disable-next-line max-params -- Currently all params are necessary, consider rewriting this logic
    (selectedDesk, selectedDay, getDeskState, selectedDays, toggleDay) => {
      if (!selectedDays.has(selectedDay)) return;
      const deskState = getDeskState(selectedDay);
      if (deskState === "disabled" || deskState === "taken" || deskState === "full") {
        toggleDay(selectedDay);
      }
    }
  );

  const submit = async () => {
    if (selectedDays.size === 0 || (selectedDesk === undefined && !freeBooking)) return;
    const deskId = selectedDesk?.id ?? "";
    setIsSubmissionLoading(true);

    const bookings = [...selectedDays].map((day) => {
      const existing = getMemberBooking(day);
      if (existing) return { ...existing, deskId };
      return { date: day.dateAsTimestamp, deskId, member: selectedMember } as Booking.AsObject;
    });
    try {
      await saveBookings(bookings);
      setCurrentStep((step) => step + 1);
      setSelectedMember(loggedInMember);
    } catch (error: unknown) {
      console.error("Failed to save bookings", error);
      setSubmitError(error as GrpcError);
    }
    setIsSubmissionLoading(false);
  };

  function renderStep() {
    switch (currentStep) {
      case 1:
        return (
          <BookingStepDaysSelect
            selectedDays={selectedDays}
            toggleDay={toggleDay}
            freeBooking={freeBooking}
          />
        );
      case 2:
        if (selectedDays.size < 1) {
          setCurrentStep(1);
          break;
        }
        return <BookingStepConfirmation selectedDays={selectedDays} toggleDay={toggleDay} />;
      case 3:
        return <BookingStepSuccess />;
    }
    return null;
  }

  function renderLoading() {
    return currentStep === 2 && isSubmissionLoading ? (
      <span className="text-statusGreen">Fancy loading animation goes here</span>
    ) : null;
  }

  function renderError() {
    return currentStep === 2 && submitError ? (
      <span className="text-statusRed">{submitError.message}</span>
    ) : null;
  }

  const deskHeader = `Boka plats ${selectedDesk?.number ?? ""}`;

  const [isMemberDialogOpen, setIsMemberDialogOpen] = useState(false);

  return (
    <div className="flex flex-col sm:mx-0 bg-black w-full max-h-full max-w-md h-auto rounded">
      <div className="flex flex-row items-center px-8 pt-8 pb-4">
        {currentStep !== 3 && (
          <>
            <DeskDetails desk={selectedDesk} />
            <h1 className="text-xl ml-2 text-projectVeryLightGray">{deskHeader}</h1>
          </>
        )}
        <div className="ml-auto">
          {!freeBooking && (
            <IconButton icon={<Svg name="Close" />} onClick={() => setSelectedDesk(undefined)} />
          )}
        </div>
      </div>
      {currentStep !== 3 && hasRole(Role.seating) && (
        <div className="flex flex-row items-center px-8 pb-4">
          <button
            className="flex items-center text-white"
            onClick={() => setIsMemberDialogOpen(true)}
          >
            {selectedMember.name}
            <Svg className="ml-2 w-4" name="PersonSwitch" />
          </button>
        </div>
      )}
      <div className="flex-1 overflow-auto px-8 py-4">
        {renderStep()}
        {renderLoading()}
        {renderError()}
      </div>
      <div className="px-8 py-4">
        <BookingFormStepButtons
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          nextDisabled={selectedDays.size === 0}
          submit={submit}
          submitLoading={isSubmissionLoading}
          clearSubmitError={() => setSubmitError(undefined)}
        />
      </div>
      <MemberSelectModal dialogOpen={isMemberDialogOpen} setDialogOpen={setIsMemberDialogOpen} />
    </div>
  );
};

export default BookingForm;
