import classnames from "classnames";
import React, { PropsWithChildren } from "react";

import { NativeButtonProps } from "../Utilities";

interface IconButtonProps {
  icon?: React.ReactNode;
}

/**
 * Renders a <button> accepting an Icon (Svg-component) param.
 */
const IconButton = ({
  icon,
  className,
  children,
  disabled,
  ...rest
}: PropsWithChildren<IconButtonProps & NativeButtonProps>) => {
  return (
    <button
      {...rest}
      className={classnames(
        "p-2 flex items-center text-projectVeryLightGray transform transition-transform duration-100 hover:scale-105",
        className,
        {
          "pointer-events-none": disabled,
        }
      )} // Consider adding generic disabled icon or color to icon
      disabled={disabled}
      onMouseDown={(e) => e.preventDefault()}
    >
      {children !== undefined && <span className="mr-3">{children}</span>}
      {icon}
    </button>
  );
};

export default IconButton;
