import { useMemo, useState } from "react";
import { createSuperContext } from "react-super-context";

import { GetBookingRangeResponse } from "../../Generated/bookings_pb";
import { dayNames } from "../Constants";
import { dateToTimestamp, Day } from "../Utilities";

const [dateContext, useDates] = createSuperContext((bookingRange: GetBookingRangeResponse) => {
  const days = useMemo(() => {
    const from = bookingRange.getFrom();
    const to = bookingRange.getTo();
    if (!from || !to) throw Error("Booking range missing");

    const toDate = to.toDate();

    const days: Day[] = [];
    let index = 0;
    // eslint-disable-next-line no-unmodified-loop-condition -- The date is mutated so the linter fails to see that it changes
    for (let date = new Date(from.toDate()); date <= toDate; date.setDate(date.getDate() + 1)) {
      days.push(<Day>{
        name: dayNames[date.getDay()],
        date: new Date(date),
        dateAsTimestamp: dateToTimestamp(date).toObject(),
        index: index++,
        dayNumber: (date.getDay() - 1 + 7) % 7,
      });
    }
    return days;
  }, [bookingRange]);

  const [selectedDay, setSelectedDay] = useState<Day>(days[0]);

  return {
    /**
     * A list of days in the current allowed booking range. Index 0 is today, 1 is tomorrow etc.
     */
    days,
    /**
     * The currently selected day (a day in {@link days}).
     */
    selectedDay,
    /**
     * Set the selected day.
     */
    setSelectedDay,
  };
});

export { dateContext, useDates };
