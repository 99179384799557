import classnames from "classnames";
import React from "react";

import { IconButton, Svg } from "../Core/Components";

interface BottomButtonsProps {
  currentStep: number;
  setCurrentStep: (number: number) => void;
  nextDisabled: boolean;
  submit: () => void;
  submitLoading: boolean;
  clearSubmitError: () => void;
}

const BookingFormStepButtons = ({
  currentStep,
  setCurrentStep,
  nextDisabled,
  submit,
  submitLoading,
  clearSubmitError,
}: BottomButtonsProps) => {
  const isLastStepBeforeSubmit = currentStep === 2;
  const nextOrConfirmText = isLastStepBeforeSubmit ? "Bekräfta" : "Nästa";
  return (
    <div
      className={classnames("flex", {
        "justify-end": !isLastStepBeforeSubmit,
        "justify-between": isLastStepBeforeSubmit,
      })}
    >
      {currentStep === 2 && (
        <IconButton
          icon={<Svg name="ArrowLeftWhite" />}
          onClick={() => {
            setCurrentStep(currentStep - 1);
            clearSubmitError();
          }}
          disabled={nextDisabled}
        />
      )}
      <IconButton
        className={currentStep >= 3 ? "hidden" : ""}
        icon={
          isLastStepBeforeSubmit ? (
            <Svg name="ArrowRightCircleGreen" />
          ) : (
            <Svg name="ArrowRightCircle" />
          )
        }
        onClick={isLastStepBeforeSubmit ? submit : () => setCurrentStep(currentStep + 1)}
        disabled={isLastStepBeforeSubmit ? submitLoading : nextDisabled}
      >
        {nextOrConfirmText}
      </IconButton>
    </div>
  );
};
export default BookingFormStepButtons;
