import React, { useState } from "react";

import { Svg } from "../Core/Components";
import { useMemberList } from "../Core/Contexts";
import { MemberSelectModal } from "../MemberSelect";
import ToolbarButton from "./ToolbarButton";

const MemberSelect = () => {
  const { selectedMember } = useMemberList();

  const [isDialogOpen, setDialogOpen] = useState(false);

  return (
    <div className="flex items-center gap-2">
      <p className="font-bold whitespace-nowrap">Reservera för:</p>
      <ToolbarButton onClick={() => setDialogOpen(true)} svgRecolorMode="stroke">
        {selectedMember.name}
        <Svg name="PersonSwitch" />
      </ToolbarButton>
      <MemberSelectModal dialogOpen={isDialogOpen} setDialogOpen={setDialogOpen} />
    </div>
  );
};

export default MemberSelect;
