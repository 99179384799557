import React from "react";

import { Svg } from "../Core/Components";

interface StatusMessageProps {
  title: string;
  message: string;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const StatusMessage = ({ title, message, onClose }: StatusMessageProps) => {
  return (
    <div className="relative bg-projectVeryLightGray py-3 px-5 text-black rounded m-4 w-fit mx-auto lg:m-0 lg:fixed lg:transform lg:-translate-x-1/2 lg:left-1/2 lg:top-4">
      <button className="absolute text-lg right-2 top-2 p-2" onClick={onClose}>
        <Svg name="Close" className="svg-fill--black" />
      </button>
      <div className="font-bold text-lg text-center">{title}</div>
      <div className="text-center">{message}</div>
    </div>
  );
};

export default StatusMessage;
