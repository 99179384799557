import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "./styles/main.css";
import { App } from "./App/App";
import { Spinner } from "./Core/Components";

const publicUrl = process.env.PUBLIC_URL || "";

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Spinner />}>
      <BrowserRouter basename={publicUrl}>
        <App />
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
