import classnames from "classnames";
import React, { Dispatch, useEffect, useState } from "react";

import { Autocomplete } from ".";
import { useSelectedIndex } from "../Hooks/SelectedIndex";
import { AutocompleteSharedProps } from "./Autocomplete";
import Input, { InputProps } from "./Input";

interface AutocompleteInputProps<T> extends InputProps, AutocompleteSharedProps<T> {
  autocompleteOptions: T[];
  setSelectedOption: Dispatch<T>;
  value: string;
  setValue: Dispatch<string>;
}

function AutocompleteInput<T>({
  autocompleteOptions,
  setSelectedOption,
  display,
  subDisplay,
  className,
  inputClassName,
  value,
  setValue,
  ...rest
}: AutocompleteInputProps<T>) {
  const { index, setIndex, moveIndex } = useSelectedIndex(autocompleteOptions.length);

  const selectAutocompleteOption = () => {
    const option = autocompleteOptions[index] as T | undefined;
    if (!option) return;
    setValue(display(option));
    setSelectedOption(option);
    setShowAutocomplete(false);
  };

  useEffect(() => {
    if (index >= autocompleteOptions.length) setIndex(0);
  }, [index, setIndex, autocompleteOptions]);

  const [showAutocomplete, setShowAutocomplete] = useState(false);

  return (
    <>
      <Input
        {...rest}
        value={value}
        onChange={(e) => {
          setShowAutocomplete(true);
          setValue(e.target.value);
        }}
        autoComplete="off"
        className={className}
        inputClassName={classnames(
          "border-solid border border-white p-3 rounded bg-transparent w-full",
          inputClassName
        )}
        onKeyDown={(e) => {
          if (e.key === "ArrowUp") {
            moveIndex(-1);
          } else if (e.key === "ArrowDown") {
            moveIndex(1);
          } else if (e.key === "Enter" && showAutocomplete && autocompleteOptions.length > 0) {
            selectAutocompleteOption();
            e.preventDefault(); // Do not submit
          }
        }}
        onFocus={() => setShowAutocomplete(true)}
        onBlur={() => setShowAutocomplete(false)}
      >
        {showAutocomplete && autocompleteOptions.length > 0 && (
          <Autocomplete<T>
            options={autocompleteOptions}
            display={display}
            subDisplay={subDisplay}
            index={index}
            setIndex={setIndex}
            selectAutocompleteOption={selectAutocompleteOption}
          />
        )}
      </Input>
    </>
  );
}

export default AutocompleteInput;
