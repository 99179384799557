import classnames from "classnames";
import React, { Dispatch, InputHTMLAttributes, PropsWithChildren, useState } from "react";

import { Svg } from ".";

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "value" | "defaultValue"> {
  label?: string;
  labelBackground?: "black" | "white";
  name: string;
  inputClassName?: string;
  setChecked?: Dispatch<boolean>;
}

const Checkbox = ({
  label,
  labelBackground = "black",
  name,
  className,
  inputClassName,
  children,
  disabled,
  checked,
  setChecked,
  ...rest
}: PropsWithChildren<CheckboxProps>) => {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <fieldset
      className={classnames(
        "flex items-center py-2 rounded border border-transparent",
        {
          "border-solid border-white": hasFocus,
        },
        className
      )}
    >
      <input
        {...rest}
        type="checkbox"
        id={name}
        disabled={disabled}
        checked={checked}
        className={classnames("custom-checkbox", { "text-gray-600": disabled }, inputClassName)}
        onChange={(event) => setChecked?.(event.target.checked)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
      />
      <label
        htmlFor={name}
        className={"w-6 h-6 rounded border-solid border-2 mr-2 border-green-400 bg-white"}
      >
        {checked && <Svg name="CheckboxIcon" full />}
      </label>
      {label && (
        <label
          htmlFor={name}
          className={classnames("select-none", {
            "bg-black": labelBackground === "black",
            "bg-white": labelBackground === "white",
          })}
        >
          {label}
        </label>
      )}
      {children}
    </fieldset>
  );
};

export default Checkbox;
