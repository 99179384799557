import { useBookings, useDates } from "../Contexts";
import { Day } from "../Utilities";

export const useOfficeFull = () => {
  const { selectedDay } = useDates();
  const { getBookingCount, maxBookingsPerDay } = useBookings();

  const checkFull = (day: Day | undefined) => getBookingCount(day) >= maxBookingsPerDay;

  const isOfficeFull = checkFull(selectedDay);

  return { checkFull, isOfficeFull };
};
