import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, PropsWithChildren, useEffect } from "react";

import { IconButton, Svg } from ".";
import { ChildrenOnlyProps } from "../Utilities";

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export const ModalHeader = ({ children }: ChildrenOnlyProps) => (
  <h2 className="Modal__header text-white text-center text-lg md:text-2xl font-bold md:leading-8">
    {children}
  </h2>
);

export const ModalBody = ({ children }: ChildrenOnlyProps) => (
  <div className="Modal__body text-white text-center md:text-md pt-4">{children}</div>
);

export const ModalButtons = ({ children }: ChildrenOnlyProps) => (
  <div className="Modal__buttons flex pt-12 pb-20 justify-center">{children}</div>
);

export const Modal = ({ open, onClose, children }: PropsWithChildren<ModalProps>) => {
  useEffect(() => {
    const escapeEventListener = (event: KeyboardEvent) => {
      if (event.code === "Escape") onClose();
    };
    document.addEventListener("keyup", escapeEventListener);
    return () => document.removeEventListener("keyup", escapeEventListener);
  });

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        open={open}
        onClose={() => {
          /* No-op (handled elsewhere)*/
        }}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <Transition.Child
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className="fixed inset-0"
            style={{ backdropFilter: "blur(3px)", background: "rgba(51, 51, 51, 0.8)" }}
          />
        </Transition.Child>

        <div className="flex relative w-full h-full" onClick={onClose}>
          <Transition.Child
            className="bg-black inline-block w-full max-w-screen-md pt-24 px-8 md:px-32 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded self-start md:self-center mx-auto transform "
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            onClick={(event: React.UIEvent) => event.stopPropagation()}
          >
            <IconButton
              className="absolute top-6 right-6"
              icon={<Svg name="Close" />}
              onClick={onClose}
            />

            <div
              className="overflow-auto px-1"
              style={{
                maxHeight: "75vh",
              }}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
