import classnames from "classnames";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Menu } from ".";
import { IconButton, Svg } from "../Core/Components";
import { Role, useDesks, useRoles } from "../Core/Contexts";
import { MemberSelectModal } from "../MemberSelect";

const Navbar = () => {
  const { selectedDesk, setSelectedDesk } = useDesks();
  const { hasRole } = useRoles();

  const { pathname } = useLocation();

  const [isActive, setActive] = useState(false);
  const toggleMenu = () => {
    if (!isActive && selectedDesk) setSelectedDesk(undefined);
    setActive(!isActive);
  };

  const [isMemberDialogOpen, setIsMemberDialogOpen] = useState(false);

  return (
    <nav className={classnames("fixed w-full", { "z-20": !isActive, "z-30": isActive })}>
      <div className="Navbar flex bg-transparent navbar-offset flex justify-between items-center px-4 md:px-8 w-full">
        {(pathname === "/" || pathname === "/free-booking") && hasRole(Role.seating) && (
          <IconButton
            className="lg:hidden"
            icon={<Svg name="PersonSwitch" />}
            onClick={() => setIsMemberDialogOpen(true)}
          />
        )}

        <Link to="/">
          <Svg name="Logo" className="w-24 sm:w-32 inline-block align-middle h-full" full />
        </Link>

        <IconButton icon={<Svg name="Hamburger" className="h-6" />} onClick={toggleMenu}>
          <span className="hidden sm:block font-bold text-white">Meny</span>
        </IconButton>
      </div>
      <Menu toggleMenu={toggleMenu} active={isActive} />
      <MemberSelectModal dialogOpen={isMemberDialogOpen} setDialogOpen={setIsMemberDialogOpen} />
    </nav>
  );
};

export default Navbar;
