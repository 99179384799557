import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";

import rabbit from "../../assets/animations/rabbit-ring_v.01.json";
import vSign from "../../assets/animations/v-sign_v.01.json";

interface LottieComponentProps {
  variant: string;
  styles?: string;
  center?: boolean;
}

const LottieComponent = ({ variant, styles, center = false }: LottieComponentProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  function getAnimationData(variant: string) {
    switch (variant) {
      case "rabbit":
        return rabbit;
      case "vSign":
        return vSign;
      default:
        return "";
    }
  }

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return undefined;
    const animation = lottie.loadAnimation({
      container,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: getAnimationData(variant),
    });

    return () => animation.destroy();
  }, [variant]);

  /*
   * @Todo add react-visibility-censor and handle if animation is not in viewport.
   * useLayoutEffect(() => {
   *   if (animation !== null) {
   *     // @ts-expect-error
   *     isVisible ? animation.play() : animation.pause();
   *   }
   * }, [isVisible]);
   */

  return (
    <div
      ref={containerRef}
      className={styles}
      style={
        center
          ? {
              left: "50%",
              top: "50%",
              position: "absolute",
              transform: "translate(-50%, -50%)",
            }
          : {}
      }
    />
  );
};

export default LottieComponent;
