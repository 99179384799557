import React from "react";

import { useFloors } from "../Core/Contexts";
import BookingMap from "./BookingMap";
import FloorSelector from "./FloorSelector";

const FloorSelectGuard = () => {
  const { selectedFloor } = useFloors();

  return selectedFloor ? <BookingMap /> : <FloorSelector />;
};

export default FloorSelectGuard;
