import orderBy from "lodash/orderBy";
import { useCallback, useEffect, useState } from "react";
import usePromise from "react-use-promise";

import { Booking, ListBookingsRequest } from "../../Generated/bookings_pb";
import {
  Role,
  useApiClient,
  useBookings,
  useMember,
  useRoles,
  useSnackbarError,
} from "../Contexts";

const orderBookings = (bookings: Booking.AsObject[]) =>
  orderBy(bookings, (booking) => booking.date?.seconds ?? 0);

export const useBookingsForOthers = () => {
  const member = useMember();
  const { hasRole } = useRoles();
  const client = useApiClient();
  const { deleteBooking } = useBookings();

  // Fetch all bookings in the current timespan
  const [bookingsResponse, bookingsError, bookingsRequestState] = usePromise(async () => {
    // Optimisation, if member is not allowed to book for others, no need to request bookings
    if (!hasRole(Role.seating)) return [];

    const request = new ListBookingsRequest();
    request.setBookedById(member.id);

    const response = await client.listBookings(request);
    const bookingsList = response.toObject().bookingsList;
    return orderBookings(bookingsList.filter((booking) => booking.member?.id !== member.id));
  }, [member, hasRole]);

  // Store bookings in state and update when request succeeds
  const [bookingsForOthers, setBookingsForOthers] = useState<Booking.AsObject[]>([]);
  useEffect(() => setBookingsForOthers(bookingsResponse ?? []), [bookingsResponse]);

  const deleteBookingForOther = useCallback(
    async (toRemove: Booking.AsObject) => {
      await deleteBooking(toRemove);
      setBookingsForOthers((bookings) => bookings.filter((booking) => booking.id !== toRemove.id));
    },
    [deleteBooking, setBookingsForOthers]
  );

  useSnackbarError(bookingsError);

  return {
    /**
     * List of bookings created by the logged in member
     */
    bookingsForOthers,
    /**
     * Delete a booking
     */
    deleteBookingForOther,
    /**
     * True of bookings are loading
     */
    isBookingsForOthersLoading: bookingsRequestState === "pending",
  };
};
