import keyBy from "lodash/keyBy";
import React, { useMemo } from "react";

import { calculatePositionStyles, DeskButton, FloorElement, floorLayout } from ".";
import { Svg } from "../../Core/Components";
import { useDesks, useFloors } from "../../Core/Contexts";

const ElementComponent = ({ element }: { element: FloorElement }) => {
  const { type, title, ...position } = element;

  const titleArray = title?.split(",");

  return (
    <div
      className="bg-projectLightGray rounded-md relative p-2"
      style={{
        ...calculatePositionStyles(position),
      }}
    >
      {titleArray?.map((title) => (
        <p
          key={title + element.x + element.y}
          className="ElementComponent__title text-right text-xs"
        >
          {title}
        </p>
      ))}
      <div
        className="absolute"
        style={{ left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
      >
        {type !== "Blank" && <Svg name={type} />}
      </div>
    </div>
  );
};

const Floor = () => {
  const { selectedFloor } = useFloors();
  const { desks } = useDesks();

  const desksByNumber = useMemo(() => keyBy(desks, "number"), [desks]);

  if (!selectedFloor) return <></>;

  const {
    desks: deskLayout,
    columns,
    rows,
    defaultDeskWidth,
    elements,
  } = floorLayout[selectedFloor.id];

  return (
    <div
      className="Floor flex-1"
      style={{
        display: "grid",
        gridTemplateRows: `repeat(${rows}, 20px)`,
        gridTemplateColumns: `repeat(${columns}, 20px)`,
        gridGap: "0.5rem",
        padding: "1.5rem",
        overflow: "auto",
        width: "fit-content",
        margin: "auto",
      }}
    >
      {elements.map((element) => (
        <ElementComponent element={element} key={element.type + element.x + element.y} />
      ))}
      {Object.keys(deskLayout).map((number) => {
        const desk = desksByNumber[number];
        const layout = deskLayout[number];
        return (
          <DeskButton
            key={number}
            number={number}
            defaultDeskWidth={defaultDeskWidth}
            deskPosition={layout}
            desk={desk}
          />
        );
      })}
    </div>
  );
};

export default Floor;
