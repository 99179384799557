import { Transition } from "@headlessui/react";
import React, { ReactNode, useEffect, useState } from "react";

interface SnackbarProps {
  message?: string;
  onClose: () => void;
  open: boolean;
  prefix?: ReactNode;
}

const DefaultExitMs = 7000;

const Snackbar = ({ message, onClose, open, prefix }: SnackbarProps) => {
  const [hasExited, setHasExited] = useState(false);
  const [prefixes, setPrefixes] = useState<ReactNode[]>([prefix]);

  useEffect(() => {
    if (!prefixes.includes(prefix)) setPrefixes([...prefixes, prefix]);
    const timeoutId = setTimeout(() => {
      setHasExited(true);
    }, DefaultExitMs);
    return () => clearTimeout(timeoutId);
  }, [prefixes, prefix]);

  if (!open || hasExited) return null;

  return (
    <Transition
      enter="ease-out duration-100"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        onClick={onClose}
        className={`min-w-32 bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-3
                        w-1/2 max-w-md mb-8 ml-8 
                        fixed left-0 bottom-0 cursor-pointer z-20 transform 
                        hover:-translate-y-1 hover:scale-110 transition ease-in-out duration-300`}
        role="alert"
      >
        <div className="flex flex-row justify-between mr-1 font-bold">
          <p className="font-bold">Boink</p>
          <p>X</p>
        </div>
        {prefixes.map((p, i) => (
          <p key={`prefix-${i}`} className="text-sm">
            {p}
          </p>
        ))}
        <p className="text-sm">{message}</p>
      </div>
    </Transition>
  );
};

export default Snackbar;
