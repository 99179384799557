import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { SuperContext } from "react-super-context";

import { Booking } from "../BookingMap";
import CleanDesk from "../CleanDesk/CleanDesk";
import { Spinner } from "../Core/Components";
import { MsalConfiguration, MsalScopes } from "../Core/Configuration";
import { apiClientContext, SnackbarContext } from "../Core/Contexts";
import FreeBooking from "../FreeBooking/FreeBooking";
import MyBookings from "../MyBookings/MyBookings";
import { Navbar } from "../Nav";
import InitialDataLoader from "./InitialDataLoader";

const clientApplication = new PublicClientApplication(MsalConfiguration);

const OverviewPage = React.lazy(() => import("../Overview/OverviewPage"));

export const App = () => {
  return (
    <MsalProvider instance={clientApplication}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{
          scopes: MsalScopes,
        }}
      >
        <div className="App min-h-screen font-sans bg-projectGray">
          <SnackbarContext>
            <SuperContext contexts={[apiClientContext]}>
              <InitialDataLoader>
                <Navbar />
                <React.Suspense fallback={<Spinner />}>
                  <Switch>
                    <Route path="/clean-desk">
                      <CleanDesk />
                    </Route>
                    <Route path="/bookings">
                      <MyBookings />
                    </Route>
                    <Route path="/free-booking">
                      <FreeBooking />
                    </Route>
                    <Route path="/overview">
                      <OverviewPage />
                    </Route>
                    <Route path="/" exact={true}>
                      <Booking />
                    </Route>
                  </Switch>
                </React.Suspense>
              </InitialDataLoader>
            </SuperContext>
          </SnackbarContext>
        </div>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
