import { keyBy } from "lodash";
import { useMemo, useState } from "react";
import { createSuperContext } from "react-super-context";

import { Desk } from "../../Generated/desks_pb";

const [deskContext, useDesks] = createSuperContext((desks: Desk.AsObject[]) => {
  const [selectedDesk, setSelectedDesk] = useState<Desk.AsObject | undefined>();

  const getDeskById = useMemo(() => {
    const desksById = keyBy(desks, "id");
    return (deskId: string | undefined) =>
      deskId === undefined ? undefined : (desksById[deskId] as Desk.AsObject | undefined);
  }, [desks]);

  return {
    /**
     * A list of desks on the currently selected Floor. Does not include disabled desks.
     */
    desks,
    /**
     * The currently selected desk (in {@link desks}).
     */
    selectedDesk,
    /**
     * Set the selected desk.
     */
    setSelectedDesk,

    /**
     * A function to find a desk by its id, will return undefined if not found. Prefer using this
     * over desksById
     */
    getDeskById,
  };
});

export { deskContext, useDesks };
