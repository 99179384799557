import { useMemo, useState } from "react";
import { createSuperContext } from "react-super-context";

import { useMember } from ".";
import { Member } from "../../Generated/members_pb";

const [memberListContext, useMemberList] = createSuperContext(() => {
  // The logged in member
  const member = useMember();

  const [selectedMember, setSelectedMember] = useState<Member.AsObject>(member);

  const hasSelectedSelf = useMemo(() => member.id === selectedMember.id, [member, selectedMember]);

  return {
    /**
     * Current selected member that bookings will be created for
     */
    selectedMember,

    /**
     * Update selected member.
     */
    setSelectedMember,

    /**
     * If the selected member is the same as the logged in member
     */
    hasSelectedSelf,
  };
});

export { memberListContext, useMemberList };
