import groupBy from "lodash/groupBy";
import React, { useEffect, useMemo, useState } from "react";

import { BookingItem, DeleteBookingModal } from ".";
import { Button, Spinner, SubPageLayout } from "../Core/Components";
import { useBookings, useDesks, useMemberBookings } from "../Core/Contexts";
import { useBookingsForOthers } from "../Core/Hooks";
import { Booking } from "../Generated/bookings_pb";

const MyBookings = () => {
  const { memberBookings } = useMemberBookings();
  const { deleteBooking } = useBookings();
  const { bookingsForOthers, isBookingsForOthersLoading, deleteBookingForOther } =
    useBookingsForOthers();
  const { getDeskById } = useDesks();

  const [selectedBooking, setSelectedBooking] = useState<Booking.AsObject>();
  const [selectedTab, setSelectedTab] = useState<"self" | "other">("self");

  const bookings = selectedTab === "self" ? memberBookings : bookingsForOthers;
  const bookingByDesk = useMemo(() => groupBy(bookings, "deskId"), [bookings]);

  useEffect(() => {
    if (bookingsForOthers.length === 0) setSelectedTab("self");
  }, [setSelectedTab, bookingsForOthers]);

  return (
    <SubPageLayout title="Mina bokningar">
      {isBookingsForOthersLoading ? (
        <Spinner />
      ) : (
        <div className="py-8 flex flex-col">
          {bookingsForOthers.length > 0 && (
            <div className="m-auto text-white w-fit flex gap-2">
              <Button
                className="border border-transparent"
                color={selectedTab === "self" ? "transparentOutlined" : "gray"}
                size="fit"
                onClick={() => setSelectedTab("self")}
              >
                Mine
              </Button>
              <Button
                className="border border-transparent"
                color={selectedTab === "other" ? "transparentOutlined" : "gray"}
                size="fit"
                onClick={() => setSelectedTab("other")}
              >
                Andre
              </Button>
            </div>
          )}
          {bookings.length === 0 ? (
            <div className="m-auto text-white w-fit mt-5">Inga bokningar.</div>
          ) : (
            Object.keys(bookingByDesk).map((deskId) => (
              <BookingItem
                key={deskId}
                deskless={deskId === ""}
                title={getDeskById(deskId)?.number ?? ""}
                bookings={bookingByDesk[deskId]}
                onClick={setSelectedBooking}
                showName={selectedTab === "other"}
              />
            ))
          )}
        </div>
      )}
      <DeleteBookingModal
        booking={selectedBooking}
        thirdPerson={selectedTab === "other"}
        deleteFunction={selectedTab === "self" ? deleteBooking : deleteBookingForOther}
        close={() => {
          setSelectedBooking(undefined);
        }}
      />
    </SubPageLayout>
  );
};

export default MyBookings;
