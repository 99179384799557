import { useCallback } from "react";
import { createSuperContext } from "react-super-context";

import { BookitMember } from "../Models";

const [memberContext, useMemberContext] = createSuperContext((member: BookitMember) => member);

const useMember = () => useMemberContext().member;
const useRoles = () => {
  const { roles } = useMemberContext();

  const hasRole = useCallback((role: string) => roles.has(role), [roles]);

  return { roles, hasRole };
};

const Role = {
  seating: "lokal-seating.kxb",
};

Object.freeze(Role);

export { memberContext, useMember, useRoles, Role };
