import React, { Dispatch, useState } from "react";

import { Button } from "../Core/Components";
import { useBookings, useDates, useDesks, useMemberBookings } from "../Core/Contexts";
import useDeskState, { DeskState } from "../Core/Hooks/DeskState";
import { Day } from "../Core/Utilities/type.utilities";
import { Booking } from "../Generated/bookings_pb";
import BookingFormDayButton from "./BookingFormDayButton";

interface ReservationDesksContainerProps {
  selectedDays: Set<Day>;
  toggleDay: Dispatch<Day>;
  freeBooking: boolean;
}

const BookingStepDaysSelect = ({
  selectedDays,
  toggleDay,
  freeBooking,
}: ReservationDesksContainerProps) => {
  const { getBooking } = useBookings();
  const { days, selectedDay } = useDates();
  const { selectedDesk } = useDesks();
  const { getMemberBooking } = useMemberBookings();
  const { getDeskById } = useDesks();

  const getDeskState = useDeskState(selectedDesk, freeBooking);

  function getDisplayText(
    deskState: DeskState,
    deskBooking?: Booking.AsObject,
    memberBooking?: Booking.AsObject
  ) {
    switch (deskState) {
      case "disabled":
        return ""; // Should not happen
      case "full":
        return memberBooking ? "Du har booket" : "Fullt på kontoret";
      case "available":
        return "Flex";
      case "taken":
        return deskBooking?.member?.name ?? "Unknown";
      case "swappable": {
        const deskNumber = getDeskById(memberBooking?.deskId ?? "")?.number;
        const fromText = deskNumber ? ` från plats ${deskNumber}` : " från plats utan skrivbord";
        const toText = selectedDesk ? ` till ${selectedDesk.number}` : " till plats utan skrivbord";

        return `Byt${fromText}${toText}`;
      }
      default:
        throw Error(`Unknown deskState '${deskState}'`);
    }
  }

  const calculateWeekendIndex = (day: Day) => Math.floor((day.index + days[0].dayNumber) / 7);

  const [showWeekends, setShowWeekends] = useState<boolean[]>(() => {
    /*
     *On initial load, the BookingForm will mark the current selected day to be booked.
     *Thus, if the current selected day is on a weekend, we need to make the weekend visible to avoid confusion.
     */
    if (selectedDay.dayNumber < 5 || freeBooking) return [];
    const weekendIndex = calculateWeekendIndex(selectedDay);
    const weekends = [];
    weekends[weekendIndex] = true;
    return weekends;
  });

  return (
    <div className="mb-2">
      {days.map((day, index) => {
        const currentDayDeskBooking = getBooking(selectedDesk, day);
        const currentDayMemberBooking = getMemberBooking(day);

        const displayDate = `${day.name} ${day.date.getDate()}.`;

        const deskState = getDeskState(day);

        const weekendIndex = calculateWeekendIndex(day);

        const shouldRenderWeekendButton =
          day.dayNumber === 5 || (day.dayNumber === 6 && index === 0);

        return (
          <React.Fragment key={`${day.name}_${day.index}`}>
            {shouldRenderWeekendButton && (
              <Button
                size="fit"
                color="gray"
                className="w-full mb-3"
                onClick={() =>
                  setShowWeekends((show) => {
                    const newValue = [...show];
                    newValue[weekendIndex] = !newValue[weekendIndex];
                    return newValue;
                  })
                }
              >
                {showWeekends[weekendIndex] ? "Dölj" : "Visa"} helg
              </Button>
            )}
            {(day.dayNumber < 5 || showWeekends[weekendIndex]) && (
              <BookingFormDayButton
                selected={selectedDays.has(day)}
                onClick={() => toggleDay(day)}
                state={deskState}
                displayText={getDisplayText(
                  deskState,
                  currentDayDeskBooking,
                  currentDayMemberBooking
                )}
                displayDate={displayDate}
                disabled={deskState === "full" || deskState === "taken"}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BookingStepDaysSelect;
