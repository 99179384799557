import classnames from "classnames";
import React, { HTMLAttributes } from "react";

type NativeButtonProps = HTMLAttributes<HTMLButtonElement>;

interface ToolbarButtonProps {
  svgRecolorMode?: "stroke" | "fill";
}

const ToolbarButton = ({
  svgRecolorMode,
  className,
  children,
  ...rest
}: ToolbarButtonProps & NativeButtonProps) => (
  <button
    {...rest}
    className={classnames(
      "h-10 rounded-md bg-projectLightGray hover:bg-sand hover:text-projectGray flex items-center justify-center whitespace-nowrap gap-2 px-4",
      {
        "svg-stroke-toolbarbutton": svgRecolorMode === "stroke",
        "svg-fill-toolbarbutton": svgRecolorMode === "fill",
      },
      className
    )}
  >
    {children}
  </button>
);

export default ToolbarButton;
