import classnames from "classnames";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

import { Svg } from ".";

interface SubPageLayoutProps {
  title?: string;
  subTitle?: string;
  showSpark?: boolean;
}

const SubPageLayout = ({
  title,
  subTitle,
  showSpark = title !== undefined,
  children,
}: PropsWithChildren<SubPageLayoutProps>) => {
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return undefined;
    const listener = (event: Event) => {
      const target = event.target as HTMLDivElement;
      setIsScrolledToTop(target.scrollTop === 0);
    };
    element.addEventListener("scroll", listener);
    return () => element.removeEventListener("scroll", listener);
  }, [setIsScrolledToTop]);

  return (
    <div className="h-screen flex flex-col overflow-y-auto relative pb-16" ref={ref}>
      <div
        className={classnames(
          "pt-24 pb-4 z-10 sticky top-0 flex-shrink-0 bg-projectGray border-b-1 border-transparent transition-all ease-in-out duration-100 bg-projectGray",
          {
            "border-projectLightGray": !isScrolledToTop,
          }
        )}
      >
        {showSpark && (
          <div className="m-auto mb-4 sm:mb-8 w-16 sm:w-32">
            <Svg name="Spark" />
          </div>
        )}
        {title !== undefined && (
          <h1 className="text-center text-white text-2xl md:text-4xl font-bold ">{title}</h1>
        )}
        {subTitle && <p className="text-center text-white my-3 md:text-xl">{subTitle}</p>}
      </div>
      {children}
    </div>
  );
};

export default SubPageLayout;
