import React from "react";

import { Button, Svg } from "../Core/Components";

interface ErrorPageProps {
  errorMessage: string;
}

const ErrorPage = ({ errorMessage }: ErrorPageProps) => {
  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center">
      <Svg name="Logo" className="w-24 sm:w-32 inline-block h-full my-8" full />
      <div className="flex flex-col flex-1 w-full items-center">
        <div className="flex text-statusRed mb-8 text-xl">Error: {errorMessage}</div>
        <Button onClick={() => window.location.reload()}>Reload page</Button>
      </div>
    </div>
  );
};

export default ErrorPage;
